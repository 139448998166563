<template>

        <div class="ui p-half clearing red segment">
            <!--<div>
                <h2 class="ui left floated header m-0"> Liste des Marques </h2>
                <div class="ui right floated primary button" @click="newMarque"> Nouveau </div>
            </div>-->
            <div class="downloadTab">
                <h3 class="ui left floated header m-0"> Liste des Marques </h3>
                <span class="right floated flex items-end" @click="newMarque">
                <svg
                        width="20"
                        height="20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0)">
                        <path
                                d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                fill="#0953AA"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0h20v20H0z"/>
                        </clipPath>
                    </defs>
                </svg>
                Ajouter
            </span>
            </div>

            <div class="ui accordion">

                <div class="title">
                    <i class="dropdown icon"></i>
                    <u>Recherche</u>
                </div>
                <div class="content">
                    <div class="ui segment">
                        <div class="ui stackable grid m-0">
                            <div class="ui row">
                                <div class="sixteen wide column">
                                    <h3 class="ui dividing padded header app-blue">
                                        Recherche
                                    </h3>
                                </div>
                            </div>
                            <div class="ui row">
                                <div class="twelve wide column">
                                    <div class="ui equal width form">
                                        <div class="two fields">
                                            <div class="field">
                                                <label>Marque</label>
                                                <input type="text" placeholder="Marque" v-model="searchQuery.label" @keyup.enter="getMarques">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="four wide column" style="margin-top: auto;margin-bottom: auto;">
                                    <button class="ui positive right labeled icon button"
                                            style="border-radius: 6px;" @click="getMarques">
                                        <i class="search icon"></i>
                                        Chercher
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

            <table class="ui selectable celled table">
                <thead>
                <tr>
                    <th class="collapsing">Actions</th>
                    <th @click="sortData('label')">
                        Marque
                        <i v-if="sort.label" class="long arrow alternate icon float-right"
                           :class="sort.label === 1 ? 'up' : 'down'"></i>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!marque_list.length"><td> Pas de données disponibles </td> </tr>
                <tr v-for="marque in marque_list">
                     <td>
                        <div class="ui primary tiny button" @click="edit(marque)"> Editer</div>
                    </td>
                    <td> {{ marque.label }}</td>
                   
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <th colspan="99">
                        <div>
                            <pagination :limit="limit" :current_page="page" :count="count" @setPage="setPage" slot="pagination"/>
                        </div>
                    </th>
                </tr>
                </tfoot>
            </table>

        </div>

</template>

<script>
    import pagination from "@/components/pagination.vue";
    export default {
        name: "marqueList",
        components:{
            pagination
        },
        data() {
            return {
                marque_list: [],
                page: 1,
                limit: 10,
                count: 0,
                searchQuery:{
                    label: null
                },
                sort: {label: 1}
            }
        },
        methods: {
            getMarques() {
                log('getMarques');
                let match = {type: 'marque'}
                if(this.searchQuery.label){
                    match.label = {$regex: this.searchQuery.label, $options: 'i'}
                }
                let data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: match,
                    options: {
                        page: parseInt(this.page),
                        limit: parseInt(this.limit)
                    }
                };
                if (!_.isEmpty(this.sort)) {
                    data.options.sort = JSON.parse(JSON.stringify(this.sort))
                }

                this.$store.requestFind(data).then(ar => {
                    this.marque_list = ar.docs;
                    this.count = ar.count;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            sortData(field) {
                if (Object.keys(this.sort)[0] !== field) {
                    this.sort = {};
                    this.sort[field] = -1
                }

                if (this.sort[field] === 1) {
                    this.sort[field] = -1
                } else {
                    this.sort[field] = 1
                }

                this.getMarques();
            },
            newMarque(){
                this.$router.push({name: 'marqueCrud'});
            },
            edit(current_marque){
                this.$router.push({name: 'marqueCrud', params:{current_marque}});
            },
            setPage(page){
                this.page = page;
                this.getMarques();
            }
        },
        mounted() {
            this.getMarques();
            $('.ui.accordion').accordion({exclusive:true});
        }
    }
</script>

<style scoped>

</style>